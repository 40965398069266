// Course List Page
export const HTTP_COURSE_LIST_FETCHING = "HTTP_COURSE_LIST_FETCHING"
export const HTTP_COURSE_LIST_SUCCESS = "HTTP_COURSE_LIST_SUCCESS"
export const HTTP_COURSE_LIST_FAILED = "HTTP_COURSE_LIST_FAILED"

// Course manage
export const HTTP_COURSE_MANAGE_CARD_FETCHING = "HTTP_COURSE_MANAGE_CARD_FETCHING"
export const HTTP_COURSE_MANAGE_CARD_SUCCESS = "HTTP_COURSE_MANAGE_CARD_SUCCESS"
export const HTTP_COURSE_MANAGE_CARD_FAILED = "HTTP_COURSE_MANAGE_CARD_FAILED"

export const HTTP_COURSE_MANAGE_INTRO_FETCHING = "HTTP_COURSE_MANAGE_INTRO_FETCHING"
export const HTTP_COURSE_MANAGE_INTRO_SUCCESS = "HTTP_COURSE_MANAGE_INTRO_SUCCESS"
export const HTTP_COURSE_MANAGE_INTRO_FAILED = "HTTP_COURSE_MANAGE_INTRO_FAILED"

export const HTTP_COURSE_MANAGE_PRICE_FETCHING = "HTTP_COURSE_MANAGE_PRICE_FETCHING"
export const HTTP_COURSE_MANAGE_PRICE_SUCCESS = "HTTP_COURSE_MANAGE_PRICE_SUCCESS"
export const HTTP_COURSE_MANAGE_PRICE_FAILED = "HTTP_COURSE_MANAGE_PRICE_FAILED"

export const HTTP_COURSE_MANAGE_DESCRIPTION_FETCHING = "HTTP_COURSE_MANAGE_DESCRIPTION_FETCHING"
export const HTTP_COURSE_MANAGE_DESCRIPTION_SUCCESS = "HTTP_COURSE_MANAGE_DESCRIPTION_SUCCESS"
export const HTTP_COURSE_MANAGE_DESCRIPTION_FAILED = "HTTP_COURSE_MANAGE_DESCRIPTION_FAILED"

export const HTTP_COURSE_MANAGE_DOCUMENT_FETCHING = "HTTP_COURSE_MANAGE_DOCUMENT_FETCHING"
export const HTTP_COURSE_MANAGE_DOCUMENT_SUCCESS = "HTTP_COURSE_MANAGE_DOCUMENT_SUCCESS"
export const HTTP_COURSE_MANAGE_DOCUMENT_FAILED = "HTTP_COURSE_MANAGE_DOCUMENT_FAILED"

export const HTTP_COURSE_MANAGE_VIDEO_LIST_FETCHING = "HTTP_COURSE_MANAGE_VIDEO_LIST_FETCHING"
export const HTTP_COURSE_MANAGE_VIDEO_LIST_SUCCESS = "HTTP_COURSE_MANAGE_VIDEO_LIST_SUCCESS"
export const HTTP_COURSE_MANAGE_VIDEO_LIST_FAILED = "HTTP_COURSE_MANAGE_VIDEO_LIST_FAILED"

export const HTTP_COURSE_MANAGE_FETCHING = "HTTP_COURSE_MANAGE_FETCHING"
export const HTTP_COURSE_MANAGE_SUCCESS = "HTTP_COURSE_MANAGE_SUCCESS"
export const HTTP_COURSE_MANAGE_FAILED = "HTTP_COURSE_MANAGE_FAILED"

export const HTTP_COURSE_MANAGE_CHAPTER_FETCHING = "HTTP_COURSE_MANAGE_CHAPTER_FETCHING"
export const HTTP_COURSE_MANAGE_CHAPTER_SUCCESS = "HTTP_COURSE_MANAGE_CHAPTER_SUCCESS"
export const HTTP_COURSE_MANAGE_CHAPTER_FAILED = "HTTP_COURSE_MANAGE_CHAPTER_FAILED"

// Register
export const HTTP_REGISTER_CURRENT_PAGE_FETCHING = "HTTP_REGISTER_CURRENT_PAGE_FETCHING"
export const HTTP_REGISTER_CURRENT_PAGE_SUCCESS = "HTTP_REGISTER_CURRENT_PAGE_SUCCESS"
export const HTTP_REGISTER_CURRENT_PAGE_FAILED = "HTTP_REGISTER_CURRENT_PAGE_FAILED"

export const HTTP_REGISTER_INFORMATION_FETCHING = "HTTP_REGISTER_INFORMATION_FETCHING"
export const HTTP_REGISTER_INFORMATION_SUCCESS = "HTTP_REGISTER_INFORMATION_SUCCESS"
export const HTTP_REGISTER_INFORMATION_FAILED = "HTTP_REGISTER_INFORMATION_FAILED"

export const HTTP_REGISTER_COURSE_CATEGORY_INTEREST_FETCHING = "HTTP_REGISTER_COURSE_CATEGORY_INTEREST_FETCHING"
export const HTTP_REGISTER_COURSE_CATEGORY_INTEREST_SUCCESS = "HTTP_REGISTER_COURSE_CATEGORY_INTEREST_SUCCESS"
export const HTTP_REGISTER_COURSE_CATEGORY_INTEREST_FAILED = "HTTP_REGISTER_COURSE_CATEGORY_INTEREST_FAILED"

export const HTTP_REGISTER_CAREER_AND_EDUCATION_FETCHING = "HTTP_REGISTER_CAREER_AND_EDUCATION_FETCHING"
export const HTTP_REGISTER_CAREER_AND_EDUCATION_SUCCESS = "HTTP_REGISTER_CAREER_AND_EDUCATION_SUCCESS"
export const HTTP_REGISTER_CAREER_AND_EDUCATION_FAILED = "HTTP_REGISTER_CAREER_AND_EDUCATION_FAILED"

export const HTTP_COURSE_MANAGE_ADD_CURRENT_PAGE_FETCHING = "HTTP_COURSE_MANAGE_ADD_CURRENT_PAGE_FETCHING"
export const HTTP_COURSE_MANAGE_ADD_CURRENT_PAGE_SUCCESS = "HTTP_COURSE_MANAGE_ADD_CURRENT_PAGE_SUCCESS"
export const HTTP_COURSE_MANAGE_ADD_CURRENT_PAGE_FAILED = "HTTP_COURSE_MANAGE_ADD_CURRENT_PAGE_FAILED"

export const HTTP_REGISTER_ADDRESS_FETCHING = "HTTP_REGISTER_ADDRESS_FETCHING"
export const HTTP_REGISTER_ADDRESS_SUCCESS = "HTTP_REGISTER_ADDRESS_SUCCESS"
export const HTTP_REGISTER_ADDRESS_FAILED = "HTTP_REGISTER_ADDRESS_FAILED"

export const HTTP_REGISTER_BUSINESS_DETAILS_FETCHING = "HTTP_REGISTER_BUSINESS_DETAILS_FETCHING"
export const HTTP_REGISTER_BUSINESS_DETAILS_SUCCESS = "HTTP_REGISTER_BUSINESS_DETAILS_SUCCESS"
export const HTTP_REGISTER_BUSINESS_DETAILS_FAILED = "HTTP_REGISTER_BUSINESS_DETAILS_FAILED"

export const HTTP_ORDER_PAYMENT_CHAPTER_AND_VIDEO_COURSE_FETCHING = "HTTP_ORDER_PAYMENT_CHAPTER_AND_VIDEO_COURSE_FETCHING"
export const HTTP_ORDER_PAYMENT_CHAPTER_AND_VIDEO_COURSE_SUCCESS = "HTTP_ORDER_PAYMENT_CHAPTER_AND_VIDEO_COURSE_SUCCESS"
export const HTTP_ORDER_PAYMENT_CHAPTER_AND_VIDEO_COURSE_FAILED = "HTTP_ORDER_PAYMENT_CHAPTER_AND_VIDEO_COURSE_FAILED"

///////////////////////// Localization Begin /////////////////////////
export const NOT_CONNECT_NETWORK = "NOT_CONNECT_NETWORK"
export const NETWORK_CONNECTION_MESSAGE = "NETWORK_CONNECTION_MESSAGE"

export const urlDomain = "https://toflyn.com"
// export const urlDomain = "http://localhost:3000"

export const serverUrl = "https://toflyn-server.onrender.com/"
// export const serverUrl = "http://localhost:3001/"

export const storageUrl = "https://toflyn-storage.onrender.com"
// export const storageUrl = "http://localhost:3002"

export const apiServerUrl = serverUrl + "api/v1"

export const bucketName = "development-toflyn"

export const imgDefaultUrl = `./assets/images/default/df-img.png`
export const videoDefaultUrl = `./assets/images/default/df-vdo.png`
export const imgDefaltCardCourse = `./assets/images/default/df-card-course-img.png`
export const img404notfound = `./assets/images/notfound/404notfound.jpg`
export const inspectCertificateUrl = `https://www.toflyn.com/inspect-certificate`

export const apiStorageUrl = storageUrl + "/api/v1"

export const paginate = 10000 // limit 10 k
export const sortASC = "ASC"
export const sortDESC = "DESC"

export const YES = "YES"
export const NO = "NO"
export const OK = "ok"
export const NOK = "nok"

export const emailSupport = "support@toflyn.com"
export const emailSubject = "Toflyn: "

export const iconCourseCategory = [
    "fluent:sport-basketball-20-filled",
    "foundation:torso-business",
    "bxs:shopping-bags",
    "ic:round-travel-explore",
    "mdi:truck-delivery",
    "zondicons:education",
    "maki:doctor",
    "map:beauty-salon",
    "map:art-gallery",
    "fluent:vehicle-car-profile-ltr-16-filled",
    "ic:baseline-science",
    "fa6-solid:computer",
    "fa-solid:photo-video",
    "ic:baseline-real-estate-agent",
    "mdi:food-fork-drink",
    "healthicons:agriculture-worker",
    "fluent:broom-24-filled",
    "mdi:google-ads",
    "carbon:media-library-filled",
    "mdi:finance",
    "icon-park-solid:diamond-necklace",
]

export const server = {

    // USER
    GET_DETECT_USERNAME_DUPLICATE_URL: `user/get-detect-username-duplicate`,
    GET_DETECT_EMAIL_DUPLICATE_URL: `user/get-detect-email-duplicate`,
    GET_DETECT_PHONE_DUPLICATE_URL: `user/get-detect-phone-duplicate`,
    GET_CITY_CATEGORY_URL: `user/get-city-category`,
    GET_STATE_CATEGORY_URL: `user/get-state-category`,
    GET_COUNTRY_CATEGORY_URL: `user/get-country-category`,
    GET_USER_MANAGE_ALL_URL: `user-manage/get-user-manage-all`,
    GET_COURSE_OWNER_BY_ID_URL: `user-manage/get-course-owner-by-id`,

    UPDATE_USER: `user/update-user`,
    UPDATE_NATURAL_PERSON: `user/update-natural-person`,
    UPDATE_JURISTIC_PERSON: `user/update-juristic-person`,
    UPDATE_USER_MANAGE_MANAGE_BY_ID_URL: `user-manage/update-user-manage-by-id`,

    DELETE_USER_MANAGE_BY_ID_URL: `user-manage/delete-user-manage-by-id`,

    // FEEDBACK
    GET_FEEDBACK_URL: `feedback/get-feedback`,

    INSERT_FEEDBACK_URL: `feedback/insert-feedback`,

    // AUTHEN
    GET_USER_BY_UID_URL: `authen/get-user-by-uid`,

    INSERT_USER_URL: `authen/insert-user`,
    INSERT_NATURAL_PERSON_URL: `authen/insert-natural-person`,
    INSERT_JURISTIC_PERSON_URL: `authen/insert-juristic-person`,
    
    GET_CAREERS: `authen/get-careers`,
    GET_EDUCATION: `authen/get-education`,
    GET_BUSINESS_CATEGORY: `authen/get-business-category`,

    CHECK_USER_ID_ON_DATABASE_URL: `authen/check-user-id-on-database`,
    USER_INFORMATION_BY_UID_URL: `authen/user-information-by-uid`,

    // LOGIN 
    GET_EMAIL_BY_USERNAME_URL: `authen/get-email-by-username`,
    GET_EMAIL_PASSWORD_IS_ON_DB_URL: `authen/get-email-password-is-on-db`,

    // COURSE MAIN
    COURSE_CATEGORY_URL: `course/course-category`,
    COURSE_CATEGORY_BY_ID_URL: `course/course-category-by-id`,
    COURSE_DETECT_URL: `course/login-detect`,
    COURSE_DOCUMENTATION_BY_ID_URL: `course/course-documentation-by-id`,
    COURSE_FORMAT_ALL_URL: `course/course-format`,
    COURSE_CERTIFICATE_ALL_URL: `course/course-certificate`,
    COURSE_UPDATE_VIEW_COURSE_URL: `course/course-update-view-course`,
    GET_CHAPTER_CATEGORY_URL: `course/get-chapter-category`,

    // BANNER
    GET_BANNER_URL: `banner/get-banner`,

    // COURSE SEARCH
    COURSE_GROUP_SEARCH_SUGGEST_URL: `course-search/course-search-suggest`,
    COURSE_GROUP_SEARCH_SUGGEST_BY_UID_URL: `course-search/course-search-suggest-by-uid`,

    // POINTVIEW
    POINTVIEW_ALL_URL: `pointview/pointview-all`,

    // COURSE MANAGE
    COURSE_MANAGE_BY_UID_URL: `course-manage/course-manage-by-uid`,
    COURSE_MANAGE_BY_UID_AND_TEXT_URL: `course-manage/course-manage-by-uid-and-text`,
    COURSE_MANAGE_SEARCH_SUGGEST_BY_UID_URL: `course-manage/course-manage-search-suggest-by-uid`,

    INSERT_NEW_COURSE_MANAGE_COURSE_URL: `course-manage/insert-new-course-manage-course`,
    INSERT_NEW_COURSE_MANAGE_MEDIA_INTRO_URL: `course-manage/insert-new-manage-course-media-intro`,
    INSERT_NEW_COURSE_MANAGE_DOCUMENT_URL: `course-manage/insert-new-course-manage-document`,
    INSERT_NEW_COURSE_MANAGE_VIDEO_LIST_URL: `course-manage/insert-new-manage-course-video`,
    INSERT_NEW_COURSE_MANAGE_CHAPTER_URL: `course-manage/insert-new-manage-course-chapter`,
    INSERT_INSPECT_REQUEST_OPEN_COURSE_URL: `course-manage/insert-inspect-request-open-course`,

    COURSE_MANAGE_ADD_COURSE_VIDEO_URL: `course-manage/course-manage-add-course-video`,
    COURSE_MANAGE_ADD_COURSE_CHAPTER_URL: `course-manage/course-manage-add-course-chapter`,
    COURSE_MANAGE_ADD_COURSE_MANAGE_URL: `course-manage/course-manage-add-course-document`,

    COURSE_MANAGE_GET_COURSE_BY_ID_URL: 'course-manage/course-manage-get-course-by-id',
    COURSE_MANAGE_GET_COURSE_VIDEO_BY_ID_URL: 'course-manage/course-manage-get-course-video-by-id',
    COURSE_MANAGE_GET_COURSE_CHAPTER_BY_ID_URL: 'course-manage/course-manage-get-course-chapter-by-id',
    COURSE_MANAGE_GET_RANK_COURSE_VIDEO_BY_ID_URL: 'course-manage/course-manage-get-rank-course-video-by-id',
    COURSE_MANAGE_GET_RANK_COURSE_CHAPTER_BY_ID_URL: 'course-manage/course-manage-get-rank-course-chapter-by-id',
    COURSE_MANAGE_GET_COURSE_DOCUMENT_BY_ID_URL: 'course-manage/course-manage-get-course-document-by-id',
    COURSE_MANAGE_GET_COURSE_MEDIA_INTRO_BY_ID_URL: 'course-manage/course-manage-get-course-media-intro-by-id',
    COURSE_MANAGE_GET_WAIT_APPROVAL_BY_ID_URL: 'course-manage/course-manage-get-wait-approval-by-id',

    COURSE_MANAGE_UPDATE_COURSE_BY_ID_URL: 'course-manage/course-manage-update-course-by-id',
    COURSE_MANAGE_UPDATE_COURSE_VIDEO_BY_ID_URL: 'course-manage/course-manage-update-course-video-by-id',
    COURSE_MANAGE_UPDATE_COURSE_CHAPTER_BY_ID_URL: 'course-manage/course-manage-update-course-chapter-by-id',
    COURSE_MANAGE_UPDATE_RANK_COURSE_VIDEO_BY_ID_URL: 'course-manage/course-manage-update-rank-course-video-by-id',
    COURSE_MANAGE_UPDATE_RANK_COURSE_CHAPTER_BY_ID_URL: 'course-manage/course-manage-update-rank-course-chapter-by-id',
    COURSE_MANAGE_UPDATE_COURSE_MEDIA_INTRO_BY_ID_URL: 'course-manage/course-manage-update-course-media-intro-by-id',
    COURSE_MANAGE_UPDATE_COURSE_DOCUMENT_BY_ID_URL: 'course-manage/course-manage-update-course-document-by-id',
    COURSE_MANAGE_UPDATE_COURSE_IS_UPDATE_BY_ID_URL: 'course-manage/course-manage-update-course-is-update-by-id',

    COURSE_MANAGE_DELETE_COURSE_URL: `course-manage/course-manage-delete-course`,
    COURSE_MANAGE_DELETE_COURSE_DOCUMENT_URL: `course-manage/course-manage-delete-course-document`,
    DELETE_COURSE_DOCUMENT_THROUGH_SPECIAL_URL: `course-manage/delete-course-document-through-special`,

    DELETE_COURSE_OVERDUE_BY_ID_URL: `course-manage/delete-course-overdue-by-id`,

    UPDATE_REUEST_OPEN_COURSE_CARD_ADJUST_BY_ID_URL: `course-manage/update-request-open-course-card-adjust-by-id`,
    UPDATE_REUEST_OPEN_COURSE_MEDIA_INTRO_ADJUST_BY_ID_URL: `course-manage/update-request-open-course-media-intro-adjust-by-id`,
    UPDATE_REUEST_OPEN_COURSE_PRICE_ADJUST_BY_ID_URL: `course-manage/update-request-open-course-price-adjust-by-id`,
    UPDATE_REUEST_OPEN_COURSE_DESCRIPTION_ADJUST_BY_ID_URL: `course-manage/update-request-open-course-description-adjust-by-id`,
    UPDATE_REUEST_OPEN_COURSE_DOCUMENT_ADJUST_BY_ID_URL: `course-manage/update-request-open-course-document-adjust-by-id`,
    UPDATE_REUEST_OPEN_COURSE_VIDEO_ADJUST_BY_ID_URL: `course-manage/update-request-open-course-video-adjust-by-id`,
    UPDATE_REUEST_OPEN_COURSE_INSPECT_BY_ID_URL: `course-manage/update-request-open-course-inspect-by-id`,

    GET_APPROVAL_STATUS_URL: `course-manage/get-approval-status`,
    // ------------------------ CLASSROOM ------------------------------------------------------
    CLASSROOM_COURSE_BY_LEARNER_ID_URL: `classroom-course/classroom-course-by-learner-id`,
    CLASSROOM_COURSE_SEARCH_SUGGEST_BY_LEARNER_ID_URL: `classroom-course/classroom-course-search-suggest-by-learner-id`,

    CLASSROOM_GET_BOOKMARK_VIDEO_COURSE_URL: `classroom-course/classroom-get-bookmark-video-course`,
    CLASSROOM_INSERT_BOOKMARK_VIDEO_COURSE_URL: `classroom-course/classroom-insert-bookmark-video-course`,
    CLASSROOM_DELETE_BOOKMARK_VIDEO_COURSE_URL: `classroom-course/classroom-delete-bookmark-video-course`, // :id

    GET_TIMER_WATCH_COURSE_BY_ID: `classroom-course/get-timer-watch-course-by-id`,
    GET_PERCENT_WATCH_VIDEO_COURSE_URL: `classroom-course/get-percent-watch-video-course`,
    GET_COURSE_VIDEO_LIST_BY_LEARNER_ID_URL: `classroom-course/get-course-video-list-by-learner-id`,
    GET_COURSE_PURCHASE_DETECTED_URL: `classroom-course/get-course-purchase-detected`,

    // GET_LEARNER_COURSE_BY_ID_URL: `classroom-course/get-learner-course-by-id`,

    CLASSROOM_UPDATE_VIEW_VIDEO_COURSE_URL: `classroom-course/classroom-update-view-video-course`,
    UPDATE_TIMER_WATCH_COURSE_BY_ID: `classroom-course/update-timer-watch-course-by-id`,
    UPDATE_PERCENT_WATCH_VIDEO_COURSE_BY_ID_URL: `classroom-course/update-percent-watch-video-course-by-id`,
    UPDATE_LEARNER_COURSE_BY_USER_ID_URL: `classroom-course/update-learner-course-by-user-id`,

    // ------------------------ COURSE SEARCH ----------------------------------------------------
    COURSE_CATEGORY_ALL_URL: `course-search/course-category-all`,
    COURSE_RATING_ALL_URL: `course-search/course-rating-all`,
    COURSE_SEARCH_BY_ID_URL: `course-search/course-search-by-id`,

    // ------------------------ COURSE -----------------------------------------------------------
    GET_ATTENT_LATEST_COURSE_BY_USER_ID_URL: `course/get-attend-latest-course-by-user-id`,

    GET_COUNT_LEARNER_BY_COURSE_ID_URL: `course/get-count-learner-by-course-id`,
    
    COURSE_GROUP_RATING_NO_LOGIN_URL: `course/course-group-rating-no-login`,
    COURSE_GROUP_POPULAR_NO_LOGIN_URL: `course/course-group-popular-no-login`,
    COURSE_MEDIA_INTRO_BY_ID_URL: `course/course-media-intro-by-id`,
    COURSE_VIDEO_LIST_BY_ID_URL: `course/course-video-list-by-id-url`,
    COURSE_BY_ID_URL: `course/course-by-id`,
    COURSE_BY_USER_ID_URL: `course/course-by-user-id`,
    GET_COURSE_CHAPTER_AND_VIDEO_BY_ID_URL: `course/get-course-chapter-and-video-by-id`,
    GET_COURSE_ALL_URL: `course/get-course-all`,

    COURSE_INSERT_INTERESTED_URL: `course/course-insert-interested`,

    COURSE_UPDATE_INTERESTED_URL: `course/course-update-interested`,

    // ------------------------ ADMIN ------------------------------------------------------------
    GET_ADMIN_BY_ID_URL: `admin/get-admin-by-id`,
    GET_ADMIN_ALL_URL: `admin/get-admin-all`,
    GET_TOKEN_BY_ID_FETCH_URL: `admin/get-token-by-id`,
    GET_DEPARTMENT_ALL_FETCH_URL: `admin/get-department-all-id`,
    GET_ADMIN_TYPE_ALL_FETCH_URL: `admin/get-admin-type-all-id`,

    INSERT_TOKEN_BY_ID_URL: `admin/insert-token-by-id`,
    INSERT_ADMIN_BY_ID_URL: `admin/insert-admin-by-id`,

    UPDATE_TOKEN_BY_ID_URL: `admin/update-token-by-id`,
    UPDATE_TOKEN_BY_USERNAME_URL: `admin/update-token-by-username`,
    UPDATE_ADMIN_BY_ID_URL: `admin/update-admin-by-id`,

    DELETE_TOKEN_BY_ID_URL: `admin/delete-token-by-id`,
    DELETE_ADMIN_BY_ID_URL: `admin/delete-admin-by-id`,

    PAUSE_ADMIN_BY_ID_URL: `admin/pause-admin-by-id`,

    DETECT_REPEAT_TOKEN_URL: `admin/detect-repeat-token`,

    // LINE 
    SEND_LINE_NOTIFY_TO_GROUP: `line-admin/line-notify`,
    LINE_NOTIFY_PAYMENT_ORDER_URL: `line-admin/line-notify-payment-order`,
    LINE_NOTIFY_COUPON_URL: `line-admin/line-notify-coupon`,
    LINE_NOTIFY_PAYMENT_CONSIDERATION_RESULT_URL: `line-admin/line-notify-payment-consideration-result`,
    LINE_NOTIFY_PAYMENT_ORDER_MANAGE_RESULT_URL: `line-admin/line-notify-payment-order-manage-result`,
    LINE_NOTIFY_USER_MANAGE_RESULT_URL: `line-admin/line-notify-user-manage-result`,

    // BANNER 
    GET_MANAGE_BANNER_all_URL: `banner/get-manage-banner-all`,

    INSERT_MANAGE_BANNER_URL: `banner/insert-manage-banner`,

    UPDATE_MANAGE_BANNER_URL: `banner/update-manage-banner`,
    UPDATE_MANAGE_BANNER_IS_ACTIVE_URL: `banner/update-manage-banner-is-active`,
    DELETE_BANNER_BY_ID_URL: `banner/delete-banner-by-id`,

    // ANNCUNCEMENT
    GET_ANNCUNCE_URL: `announcement/get-announcement`,
    GET_ANNCUNCE_BY_ID_URL: `announcement/get-announcement-by-id`,

    GET_ANNCUNCEMENT_ALL_URL: `admin/get-announcement-all`,
    INSERT_ANNCUNCEMENT_BY_ID_URL: `admin/insert-announcement-by-id`,
    UPDATE_ANNCUNCEMENT_BY_ID_URL: `admin/update-announcement-by-id`,
    DELETE_ANNCUNCEMENT_BY_ID_URL: `admin/delete-announcement-by-id`,

    // INSPECTION REQUEST OPEN COURSE
    GET_INSPECTION_REQUEST_OPEN_COURSE_ALL_URL: `admin/get-inspection-request-open-course-all`,
    GET_INSPECTION_COURSE_CATEGORY_URL: `admin/get-inspection-course-category`,
    GET_INSPECTION_COURSE_APPROVAL_CATEGORY_URL: `admin/get-inspection-course-approval-category`,

    GET_ADMIN_INSPECTION_OPEN_COURSE_BY_ID_URL: `admin/get-admin-inspect-open-course-by-id`,
    GET_TEACHER_ADJUST_COURSE_BY_ID_URL: `admin/get-teacher-adjust-course-by-id`,
    GET_ADMIN_INSPECTION_EDIT_COURSE_BY_ID_URL: `admin/get-admin-inspect-edit-course-by-id`,

    GET_COURSE_INSPECT_BY_ID_URL: `admin/get-course-inspect-by-id`,
    GET_COURSE_INSPECT_MEDIA_INTRO_BY_ID_URL: `admin/get-course-inspect-media-intro-by-id`,
    GET_COURSE_INSPECT_DOCUMENT_BY_ID_URL: `admin/get-course-inspect-document-by-id`,
    GET_COURSE_INSPECT_VIDEO_BY_ID_URL: `admin/get-course-inspect-video-by-id`,
    GET_COURSE_INSPECT_CHAPTER_BY_ID_URL: `admin/get-course-inspect-chapter-by-id`,

    UPDATE_COURSE_INSPECT_CARD_BY_ID_URL: `admin/update-course-inspect-card-by-id-url`,
    UPDATE_COURSE_INSPECT_PRICE_BY_ID_URL: `admin/update-course-inspect-price-by-id-url`,
    UPDATE_COURSE_INSPECT_DESCRIPTION_BY_ID_URL: `admin/update-course-inspect-description-by-id-url`,
    UPDATE_COURSE_INSPECT_MEDIA_INTRO_BY_ID_URL: `admin/update-course-inspect-media-intro-by-id-url`,
    UPDATE_COURSE_INSPECT_DOCUMENT_BY_ID_URL: `admin/update-course-inspect-document-by-id-url`,
    UPDATE_COURSE_INSPECT_VIDEO_BY_ID_URL: `admin/update-course-inspect-video-by-id-url`,
    UPDATE_COURSE_INSPECT_CHAPTER_BY_ID_URL: `admin/update-course-inspect-chapter-by-id-url`,
    UPDATE_COURSE_INSPECT_BY_ID_URL: `admin/update-course-inspect-by-id-url`,
    UPDATE_COURSE_OVERDUE_BY_ID_URL: `admin/update-course-overdue-by-id-url`,
    
    // PAYMENT
    GET_INSPECT_PAYMENT_ORDER_TRANSFER_ALL_URL: `admin/get-inspect-payment-order-transfer-all`,
    GET_MANAGE_PAYMENT_ORDER_ALL_URL: `admin/get-manage-payment-order-all`,
    GET_PAYMENT_STATUS_URL: `admin/get-payment-status`,

    UPDATE_INSPECT_PAYMENT_ORDER_URL: `admin/update-inspect-payment-order`,

    DELETE_PAYMENT_ORDER_BY_ID_URL: `admin/delete-payment-order-by-id`,

    // COUPON
    GET_COUPON_MANAGE_ALL_URL: `coupon-manage/get-coupon-manage-all`,

    INSERT_COUPON_MANAGE_URL: `coupon-manage/insert-coupon-manage`,

    UPDATE_COUPON_MANAGE_BY_Id_URL: `coupon-manage/update-coupon-manage-by-id`,

    // ------------------------ VERIFY IDENTITY -------------------------------------------------
    GET_INSPECT_ACCOUNT_VERIFY_URL: `admin/get-inspect-account-verify`,
    GET_INSPECT_FILE_REGISTRATION_CERTIFICATE_URL: `admin/get-inspect-file-registration-certificate`,

    // VERIFY IDENTITY INSPECTION
    UPDATE_INSPECT_ACCOUNT_VERIFY_URL: `admin/update-inspect-account-verify`,
    UPDATE_INSPECT_ACCOUNT_VERIFY_IDENTIFY_URL: `admin/update-inspect-account-verify-identify`,
    UPDATE_INSPECT_FILE_REGISTRATION_CERTIFICATE_URL: `admin/update-inspect-file-registration-certificate`,
    UPDATE_INSPECT_ACCOUNT_VERIFY_BANK_URL: `admin/update-inspect-account-verify-bank`,
    UPDATE_IS_HAS_BEEN_ACCOUNT_VERIFIED_BANK_BY_ID_URL: `admin/update-is-has-been-account-verified-bank-by-id`,

    // INSPECTION REQUEST EDIT COURSE
    GET_INSPECTION_REQUEST_EDIT_COURSE_ALL_URL: `admin/get-inspect-request-edit-course-all`,
    GET_INSPECTION_REQUEST_EDIT_COURSE_BY_ID_URL: `admin/get-inspect-request-edit-course-by-id`,
    GET_STATUS_INSPECTION_REQUEST_EDIT_COURSE_BY_ID_URL: `admin/get-status-inspect-request-edit-course-by-id`,

    UPDATE_COURSE_INSPECT_REQUEST_EDIT_BY_ID_URL: `admin/update-course-inspect-request-edit-by-id`,
    UPDATE_INSPECT_REUEST_EDIT_COURSE_CARD_BY_ID_URL: `admin/update-inspect-request-edit-course-card-by-id`,
    UPDATE_INSPECT_REUEST_EDIT_COURSE_PRICE_BY_ID_URL: `admin/update-inspect-request-edit-course-price-by-id`,
    UPDATE_INSPECT_REUEST_EDIT_COURSE_DESCRIPTION_BY_ID_URL: `admin/update-inspect-request-edit-course-description-by-id`,
    UPDATE_INSPECT_REUEST_EDIT_COURSE_MEDIA_INTRO_BY_ID_URL: `admin/update-inspect-request-edit-course-media-intro-by-id`,
    UPDATE_INSPECT_REUEST_EDIT_COURSE_VIDEO_BY_ID_URL: `admin/update-inspect-request-edit-course-video-by-id`,
    UPDATE_INSPECT_REUEST_EDIT_COURSE_CHAPTER_BY_ID_URL: `admin/update-inspect-request-edit-course-chapter-by-id`,
    UPDATE_INSPECT_REUEST_EDIT_COURSE_DOCUMENT_BY_ID_URL: `admin/update-inspect-request-edit-course-document-by-id`,
    UPDATE_REUEST_EDIT_COURSE_INSPECT_BY_ID_URL: `course-manage/update-request-edit-course-inspect-by-id`,
    
    // INSPECTION FORM REQUEST OPEN COURSE
    GET_INSPECTION_FORM_REQUEST_OPEN_COURSE_ALL_URL: `admin/get-inspect-form-request-open-course-all`,
    GET_INSPECTION_FORM_REQUEST_OPEN_COURSE_BY_ID_URL: `admin/get-inspect-form-request-open-course-by-id`,
    GET_OPEN_COURSE_TOKEN_BY_ID_URL: `admin/get-open-course-token-by-id`,

    UPDATE_INSPECTION_FORM_REQUEST_OPEN_COURSE_URL: `admin/update-inspect-form-request-open-course`,
    UPDATE_INSPECTION_FORM_REQUEST_OPEN_COURSE_IS_OVERDUE_URL: `admin/update-inspect-form-request-open-course-is-overdue`,

    UPDATE_OPEN_COURSE_TOKEN_BY_ID_URL: `admin/update-open-course-token-by-id`,
    INSERT_OPEN_COURSE_TOKEN_BY_ID_URL: `admin/insert-open-course-token-by-id`,
    DETECT_REPEAT_OPEN_COURSE_TOKEN_URL: `admin/detect-repeat-open-course-token`,

    // FEE
    GET_FEE_PERCENTAGE_CATEGORY_URL: `admin/get-fee-percentage-category`,

    INSERT_PAY_FEE_URL: `admin/insert-pay-fee`,

    // EARNED SALES REVENUE MANAGE
    GET_EARNED_SALES_REVENUE_MANAGE_ALL_URL: `admin/get-earned-sales-revenue-manage-all`,

    UPDATE_EARNED_SALES_REVENUE_MANAGE_BY_ID_URL: `admin/update-earned-sales-revenue-manage-by-id`,

    // RESERVED WORD
    GET_MANAGE_RESERVED_WORD_ALL_URL: `admin/get-manage-reserved-word-all`,

    UPDATE_MANAGE_RESERVED_WORD_BY_Id_URL: `admin/update-manage-reserved-word-by-id`,

    DELETE_MANAGE_RESERVED_WORD_BY_Id_URL: `admin/delete-manage-reserved-word-by-id`,

    INSERT_MANAGE_RESERVED_WORD_URL: `admin/insert-manage-reserved-word`,

    // ------------------------ ACCOUNT ----------------------------------------------------------
    GET_ACCOUNT_VERIFY_BY_ID_URL: `account/get-account-verify-by-id`,
    GET_ACCOUNT_VERIFY_IDENTIFY_BY_ID_URL: `account/get-account-verify-identify-by-id`,
    GET_FILE_REGISTRATION_CERTIFICATE_BY_ID_URL: `account/get-file-registration-certificate-by-id`,
    GET_FILE_AUTHORIZED_SIGNATORY_BY_ID: `account/get-file-authorized-signatory-by-id`,
    GET_ACCOUNT_VERIFY_BANK_BY_ID_URL: `account/get-account-verify-bank-by-id`,
    GET_ACCOUNT_VERIFY_TOKEN_ALL_URL: `account/get-account-verify-token-all`,
    GET_ACCOUNT_VERIFY_TOKEN_BY_ID_URL: `account/get-account-verify-token-by-id`,
    GET_BANK_CATEGORY_ALL_URL: `account/get-bank-category-all`,

    INSERT_ACCOUNT_VERIFY_TOKEN_URL: `account/insert-account-verify-token`,
    INSERT_ACCOUNT_VERIFY_URL: `account/insert-account-verify`,
    INSERT_ACCOUNT_VERIFY_IDENTIFY_URL: `account/insert-account-verify-identify`,
    INSERT_ACCOUNT_VERIFY_BANK_URL: `account/insert-account-verify-bank`,

    INSERT_FILE_REGISTRATION_CERTIFICATE_URL: `account/insert-file-registration-certificate`,
    INSERT_FILE_AUTHORIZED_SIGNATORY_BY_ID: `account/insert-file-authorized-signatory-by-id`,

    UPDATE_ACCOUNT_VERIFY_BY_ID_URL: `account/update-account-verify-by-id`,
    UPDATE_ACCOUNT_VERIFY_TOKEN_BY_ID_URL: `account/update-account-verify-token-by-id`,
    UPDAT_ACCOUNT_VERIFY_EMAIL_BY_ID_URL: `account/update-account-verify-email-by-id`,
    UPDAT_ACCOUNT_VERIFY_IDENTIFY_BY_ID_URL: `account/update-account-verify-identify-by-id`,
    UPLOAD_ACCOUNT_VERIFY_IDENTIFY_BY_ID_URL: `account/upload-account-verify-identify-by-id`,
    UPLOAD_ACCOUNT_VERIFY_BANK_BY_ID_URL: `account/upload-account-verify-bank-by-id`,
    UPDATE_IS_OPEN_BANK_ACCOUNT_VERIFY_BY_ID: `account/update-is-open-bank-account-verify-by-id`,
    
    DETECT_ACCOUNT_REPEAT_TOKEN_BY_ID_URL: `account/detect-account-verify-repeat-token-by-id`,

    DELETE_FILE_REGISTRATION_CERTIFICATE_BY_ID_URL: `account/delete-file-registration-certificate-by-id`,
    DELETE_FILE_AUTHORIZED_SIGNATORY_BY_ID_URL: `account/delete-file-authorized-signatory-by-id`,

    // ------------------------ OpenCourse ----------------------------------------------------------
    INSERT_OPEN_COURSE_URL: `course/open-course`,

    // ------------------------ TEXT EDITOR ---------------------------------------------------------
    IMAGE_TEXT_EDITOR: `text-editor/image-text-editor`,

    // ------------------------ COURSE SHOP ---------------------------------------------------------
    GET_DETECT_COURSE_HAS_BEEN_PURCHASED_URL: `course-shop/get-detect-course-has-been-purchased`,
    GET_PAYMENT_ORDER_BY_COURSE_ID_URL: `course-shop/get-payment-order-by-course-id`,

    // ------------------------ FEEDBACK ------------------------------------------------------------
    GET_INSPECT_FEEDBACK_ALL: `admin/get-inspect-feedback-all`,
    DELETE_FEEDBACK_BY_ID_URL: `admin/delete-feedback-by-id`,
    UPDATE_INSPECT_FEEDBACK_BY_ID_URL: `admin/update-inspect-feedback-by-id`,

    // ------------------------ CERTIFICATE ---------------------------------------------------------
    GET_THEME_CERTIFICATE_ALL_URL: `certificate/get-theme-certificate-all`,
    GET_THEME_CERTIFICATE_BY_ID_URL: `certificate/get-theme-certificate-by-id`,
    GET_CERTIFICATE_CODE_BY_ID_URL: `certificate/get-certificate-code-by-id`,
    GET_COURSE_CERTIFICATE_BY_ID_URL: `certificate/get-course-certificate-by-id`,
    GET_INSPECT_COURSE_CERTIFICATE_BY_ID_URL: `certificate/get-inspect-course-certificate-by-id`,

    INSERT_COURSE_CERTIFICATE_URL: `certificate/insert-course-certificate`,

    UPDATE_COURSE_CERTIFICATE_DOWNLOAD_FIRST_TIME_URL: `certificate/update-course-certificate-download-first-time`,
    UPDATE_THEME_COURSE_CERTIFICATE_BY_ID_URL: `certificate/update-theme-course-certificate-by-id`,
    UPDATE_CERTIFICATE_APPROVAL_CATEGORY_BY_ID_URL: `certificate/update-certificate-approval-category-by-id`,

    // ------------------------ MY CHANNEL ----------------------------------------------------------
    GET_COURSE_SORT_BY_SEARCH_LOGIN_URL: `my-channel/get-course-sort-by-search-login`,

    // ------------------------ FORM REQUEST OPEN COURSE --------------------------------------------
    GET_FORM_REQUEST_OPEN_COURSE_ALL_URL: `open-course/get-form-request-open-course-all`,
    
    INSERT_FORM_REQUEST_OPEN_COURSE_URL: `open-course/insert-form-request-open-course`,
    INSERT_FORM_REQUEST_OPEN_COURSE_TOPIC_URL: `open-course/insert-form-request-open-course-topic`,

    UPDATE_FORM_REQUEST_OPEN_COURSE_URL: `open-course/update-form-request-open-course`,
    UPDATE_FORM_REQUEST_OPEN_COURSE_UPLOADED_URL: `open-course/update-form-request-open-course-uploaded`,

    DELETE_FORM_REQUEST_OPEN_COURSE_URL: `open-course/delete-form-request-open-course`,

    // ------------------------ PAYMENT -------------------------------------------------------------
    GET_PAYMENT_ALL_URL: `payment/get-payment-all`,
    GET_PAYMENT_ORDER_BY_USER_ID_URL: `payment/get-payment-order-by-user-id`,
    GET_PAYMENT_ORDER_DETECT_RE_BUY_BY_USER_ID_URL: `payment/get-payment-order-detect-re-buy-by-user-id`,
    GET_PAYMENT_CREDIT_DEBIT_TRANSACTION: `payment/get-payment-credit-debit-transaction`,
    GET_PAYMENT_PROMPT_PAY_CHARGE_URL: `payment/get-payment-prompt-pay-charge`,

    INSERT_LEARNER_COURSE_URL: `payment/insert-learner-course`,
    INSERT_PAYMENT_ORDER_URL: `payment/insert-payment-order`,
    INSERT_PAYMENT_COURSE_VIDEO_URL: `payment/insert-payment-course-video`,
    INSERT_EARNED_SALES_REVENUE_URL: `payment/insert-earned-sales-revenue`,

    CHECKOUT_CREDIT_CARD_URL: `payment/checkout-credit-card`,
    CHECKOUT_PROMPT_PAY_URL: `payment/checkout-prompt-pay`,

    UPDATE_PAYMENT_CREDIT_DEBIT_TRANSACTION: `payment/update-payment-credit-debit-transaction`,
    UPDATE_PAYMENT_PROMPT_PAY_TRANSACTION: 'payment/update-payment-prompt-pay-transaction',
    UPDATE_PAYMENT_ORDER_EXPIRE_BY_ID_URL: `payment/update-payment-oder-expire-by-id`,
    UPDATE_COURSE_LEARNER_EXPIRE_BY_ID_URL: `payment/update-course-learner-expire-by-id`,
    
    // ------------------------ MONITOR -------------------------------------------------------------
    GET_EARNED_SALES_REVENUE_MONITOR_BY_ID_URL: `monitor/get-earned-sales-revenue-monitor-by-id`,

    // COURSE MONITOR
    GET_COURSE_MONITOR_BY_ID_URL: `course-monitor/get-course-monitor-by-id`,

    // ------------------------ CHAT ----------------------------------------------------------------
    // RESERVED WORD
    GET_RESERVED_WORD_ALL_URL: `reserved-word/get-reserved-word-all`,

    // ------------------------ REVIEW --------------------------------------------------------------
    GET_COURSE_REVIEW_LIST_BY_COURSE_ID_URL: `review/get-course-review-list-by-course-id`,
    GET_COURSE_REVIEW_OVERVIEW_BY_COURSE_ID_URL: `review/get-course-review-overview-by-course-id`,
    GET_COURSE_REVIEW_BY_USER_ID_URL: `review/get-course-review-by-user-id`,

    INSERT_COURSE_REVIEW_BY_USER_ID_URL: `review/insert-course-review-by-user-id`,
    INSERT_COURSE_REVIEW_OVERVIEW_URL: `review/insert-course-review-overview`,

    UPDATE_COURSE_REVIEW_LIST_BY_USER_ID_URL: `review/update-course-review-list-by-user-id`,
    UPDATE_COURSE_REVIEW_OVERVIEW_BY_COURSE_ID_URL: `review/update-course-review-overview-by-course-id`,

    // ------------------------ COMPLAINT -----------------------------------------------------------
    GET_COMPLAINT_ALL_URL: `complaint/get-complaint-all`,
    GET_USER_TYPE_URL: `complaint/get-user-type`,
    GET_COMPLAINT_ACTION_STATUS_URL: `complaint/get-complaint-action-status`,

    INSERT_COMPLAINT_URL: `complaint/insert-complaint`,

    UPDATE_COMPLAINT_BY_ID_URL: `complaint/update-complaint-by-id`,

    // ------------------------ SALEPAGE ------------------------------------------------------------
    GET_SALEPAGE_BY_ID_URL: `salepage/get-salepage-by-id`,

    INSERT_SALEPAGE_IMAGE_URL: `salepage/insert-salepage-image`,
    INSERT_SALEPAGE_IMAGE_ITEM_URL: `salepage/insert-salepage-image-item`,
    INSERT_SALEPAGE_VIDEO_URL: `salepage/insert-salepage-video`,
    INSERT_SALEPAGE_VIDEO_ITEM_URL: `salepage/insert-salepage-image-video`,

    // ------------------------ STORAGE -------------------------------------------------------------
    DELETE_FILE_BY_Id_URL: 'remove/file',

    // ------------------------ COUPON --------------------------------------------------------------
    GET_CURRENCY_URL: `coupon/get-currency`,
    GET_COUPON_TYPE_URL: `coupon/get-coupon-type`,
    GET_COUPON_PRIVILEGE_TYPE_URL: `coupon/get-coupon-privilege-type`,
    GET_INSPECT_USED_COUPON_LOG_BY_ID_URL: `coupon/get-inspect-used-coupon-log-by-id`,
    GET_INSPECT_COUPON_BY_ID_URL: `coupon/get-inspect-coupon-by-id`,

    INSERT_COUPON_LOG_URL: `coupon/insert-coupon-log`,

    UPDATE_PERMISSION_USED_COUPON_BY_ID_URL: `coupon/update-permission-used-coupon-by-id`,

    // ------------------------ EMAIL SERVICE -------------------------------------------------------
    SEND_EMAIL_SERVICE_URL: `email-service/send-email`
}
